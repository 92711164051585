import React, { Component } from 'react';

class Loader extends Component {
    render() {
        return (
            <div className={"loader-height"}>
                <img alt={"loader"} src="https://i.pinimg.com/originals/75/9c/22/759c22458ddb79bddc794bee5c18bb9f.gif" />
            </div>
        );
    }
}

export default Loader;