import React, { Component } from 'react';

import { client } from "../index";

import VariantSelector from './VariantSelector';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { compose } from 'redux';

import { STORE_CHECKOUT_CLIENT , GET_CHECKOUT_ID } from '../utils/redux-utils';

import Loader from "../utils/Loader";

const { renderMetaTag } = require('../utils/seoTags');

class ProductDetails extends Component {

    constructor() {
        super();
        this.state = {
            productDetails: {},
            selectedOptions: {},
            loader: false,
            availableForSale: false,
        }
    }

    componentDidMount = async () => {

        const productHandle = window.location.pathname.split("/product/")[1];

        await this.fetchProductByHandle(productHandle);

        let { variants = [] , availableForSale } = this.state.productDetails || [];

        let variant = (variants && variants[0] ? variants[0] : "");

        if (variant) {

            const selectedOptions = {};

            variant.selectedOptions.forEach((eachVariant) => { selectedOptions[eachVariant.name] = eachVariant.value })

            return this.setState({ availableForSale , selectedVariantImage: variant.image.src, selectedVariant: variant, selectedOptions, price: variant.price });
        }

        window.location.href = `${window.location.origin}/not-found`
    }

    fetchProductByHandle = async (handle) => {

        const { product } = client || {};

        try {

            this.updateState({ loader: true });

            const productDetails = await product.fetchByHandle(handle);

            this.updateState({ productDetails ,  loader: false });

        } catch (error) {
            window.location.href = `${window.location.origin}/not-found`
        }
    }

    handleOptionChange = async (event) => {

        const { product } = client || {};

        const { name, value } = event.target;

        let { productDetails } = this.state;

        let selectedOptions = this.state.selectedOptions;

        selectedOptions[name] = value;

        const selectedVariant = product.helpers.variantForOptions(productDetails, selectedOptions);

        if (selectedVariant) {
            this.setState({
                selectedVariant: selectedVariant,
                selectedVariantImage: selectedVariant.image.src
            })
        }
    }

    addVariantToCart = async (variantId, quantity, type) => {

        this.updateState({ loader: true });

        const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

        const checkoutId = GET_CHECKOUT_ID();

        const response = await client.checkout.addLineItems(checkoutId, lineItemsToAdd);

        STORE_CHECKOUT_CLIENT(this.props, { checkout: response });

        this.updateState({ loader: false });

        if (type) this.props.history.push("/cart");

        else window.location.reload();
    }

    updateState = (state) => this.setState(state)

    render() {

        let { productDetails = {}, selectedVariantImage = "", price, selectedVariant, loader, availableForSale } = this.state;

        let { title = "", description = "", productType = "", images = [], options = [], descriptionHtml = "" } = productDetails || {};

        return (
            <div>
                {renderMetaTag({
                    title,
                    description,
                    keywords: productType
                })}
                {(!loader) ? <div className="page-width flex flex-column-mobile main-content">
                    <div className="medium-up--one-half">
                        <div>
                            {selectedVariantImage ? <img alt="Product Details" className="medium-up--one-half-img" src={selectedVariantImage} /> : null}
                        </div>
                        <br></br><br></br>
                        <div className="flex-wrap ">
                            {images.map((each) => <img alt={each.id} key={each.key} onClick={() => { this.setState({ selectedVariantImage: each.src }) }} className="product-single__thumbnail" src={each.src} />)}
                        </div>
                    </div>

                    {title ? <div className="medium-up--one-half">
                        <div className="product-single__title">{title}</div>
                        <div className="Product__price">${price}</div>
                        {!availableForSale ? <div className="soldOut">
                            <div className="red-color">Currently Unavailable</div> <br></br>
                            <div>We don't know when or if this item will be back in stock</div>
                        </div> : null}
                        <div>
                            <div className="flex space-between">
                                {options.map((option) =>
                                    <VariantSelector
                                        handleOptionChange={this.handleOptionChange}
                                        key={option.id.toString()}
                                        option={option}
                                    />)}
                            </div>
                        </div>

                        <div>
                            <button className={!availableForSale ? "addToCart secondary-color cursor-not-allow" : "addToCart secondary-color"} disabled={!availableForSale} onClick={() => this.addVariantToCart(selectedVariant.id, 1 , false)}>ADD TO CART</button>
                        </div>
                        <div>
                            <button className={!availableForSale ? "addToCart primary-color cursor-not-allow" : "addToCart primary-color"} disabled={!availableForSale} onClick={() => this.addVariantToCart(selectedVariant.id, 1 , true)}>BUY IT NOW</button>
                        </div>

                        <div className="product-single__description">
                            <div dangerouslySetInnerHTML={{ __html: descriptionHtml }}></div>
                        </div>

                        <br></br>

                        <div>
                            <div className="secured-checkout display-none"> </div>
                        </div>

                    </div> : null}
                </div> : <Loader/>}
            </div>
        );
    }
}

export default compose(withRouter, connect())(ProductDetails);