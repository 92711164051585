import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Client from 'shopify-buy';
import './app.css';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from "./store/store";
const { store, persistor } = configureStore();

const { config: { STORE_ACCESS_TOKEN, PRODUCTS_DATA_PULL_DOMAIN } } = require('./config')

export const client = Client.buildClient({
  storefrontAccessToken: STORE_ACCESS_TOKEN,
  domain: PRODUCTS_DATA_PULL_DOMAIN
});


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App client={client} />
    </PersistGate>
  </Provider>
  , document.getElementById('root')
);
