import React, { Component } from 'react';
import Products from '../components/Products';
import { client } from "../index";
import { renderMetaTag } from '../utils/seoTags';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { GET_CHECKOUT_CLIENT, STORE_CHECKOUT_CLIENT , GET_CHECKOUT_ID } from '../utils/redux-utils';
import Loader from "../utils/Loader";
import { fetchSideMenuBar } from '../utils/index';

class ProductListing extends Component {

  constructor() {
    super();

    this.state = {
      products: [],
      checkout: { lineItems: [] },
      title: "",
      filterType: "RELEVANCE",
      category: "",
      limit: 8,
      currentPage: 1,
      loader: false,
      hasNextPage: false,
      hasPreviousPage: false,
      fullProductDataForPagination: [],
      menuBarItems: []
    }

    this.addVariantToCart = this.addVariantToCart.bind(this);
  }

  addVariantToCart = async (variantId, quantity) => {

    const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

    const checkoutId = GET_CHECKOUT_ID();

    const response = await client.checkout.addLineItems(checkoutId, lineItemsToAdd);

    STORE_CHECKOUT_CLIENT(this.props, { checkout: response });

    this.props.history.push("/cart");
  
  }

  updateState = (state) => this.setState(state);

  componentDidMount = async () => {

    this.updateState({ loader: true });

    const { checkout } = client || {};

    if (!GET_CHECKOUT_CLIENT()) {
      const checkoutResponse = await checkout.create();
      STORE_CHECKOUT_CLIENT(this.props, { checkout: checkoutResponse });
      this.updateState({ checkout: GET_CHECKOUT_CLIENT() });
    }

    const pageExists = window.location.pathname.split("/collection/")[1];

    if (pageExists) {

      await this.fetchAllProductsByCollectionName(pageExists);

      return this.setState({ title: pageExists , loader: false , menuBarItems: await fetchSideMenuBar()});
    }

    await this.changeSortingKey();

   this.setState({ menuBarItems: await fetchSideMenuBar()});
  }

  changeSortingKey = async () => {

    const { product } = client || {};

    const { limit, filterType } = this.state;

    const productResponse = await product.fetchQuery({
      first: limit,
      sortKey: filterType,
    });

    let { hasNextPage = false } = productResponse[productResponse.length - 1] || {};

    let { hasPreviousPage = false } = (productResponse && productResponse[0]) || {};

    this.updateState({
      fullProductDataForPagination: productResponse,
      products: productResponse, loader: false, hasNextPage, hasPreviousPage
    });
  }

  uniqBy = (arr, predicate) => {
    const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate];

    return [...arr.reduce((map, item) => {
      const key = (item === null || item === undefined) ?
        item : cb(item);

      map.has(key) || map.set(key, item);

      return map;
    }, new Map()).values()];
  };

  nextPageProduct = async () => {

    this.updateState({ loader: true });

    let { products , fullProductDataForPagination , currentPage } = this.state;

    const productResponse = await client.fetchNextPage(products);

    let { data } = productResponse || {};

    let { shop } = data || {};

    let { pageInfo} = (shop && shop.products) || {};

    let { hasNextPage , hasPreviousPage } = pageInfo || {};  

    fullProductDataForPagination = fullProductDataForPagination.concat(productResponse.model);

    this.updateState({
      products: productResponse.model, currentPage: (currentPage + 1),
      fullProductDataForPagination: this.uniqBy(fullProductDataForPagination, 'id')
      , loader: false, hasNextPage, hasPreviousPage
    });
  }

  previousPage = async () => {

    const { limit } = this.state;

    this.updateState({ loader: true });

    let { products , fullProductDataForPagination , currentPage } = this.state;

    const currentProductIds = products.map((each) => each.id);

    let removeCurrentProduct = fullProductDataForPagination.filter(each => !currentProductIds.includes(each.id));

    removeCurrentProduct = this.uniqBy(removeCurrentProduct, 'id')

    const productResponse = removeCurrentProduct.slice((removeCurrentProduct.length - limit), removeCurrentProduct.length);

    this.updateState({
      products: productResponse, currentPage: (currentPage - 1),
      fullProductDataForPagination: removeCurrentProduct,
      loader: false, hasNextPage: true, hasPreviousPage: (removeCurrentProduct.length > limit),
    });
  }

  fetchAllProductsByCollection = async (collectionId , name) => {

    try {
      const { collection } = client || {};

      this.updateState({ loader: true });

      const { limit } = this.state;

      const collections = await collection.fetchWithProducts(`${collectionId}`, { productsFirst: limit });

      this.updateState({ products: collections.products, loader: false , title: name });

    } catch (error) {
      window.location.href = `${window.location.origin}/not-found`;
    }
  }

  fetchAllProductsByCollectionName = async (collectionName) => {

    try {
      const { collection } = client || {};

      const collections = await collection.fetchByHandle(collectionName);

      this.updateState({ products: collections.products });

    } catch (error) {
      window.location.href = `${window.location.origin}/not-found`;
    }
  }

  onValueChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, () => {
      if (name === 'filterType') return this.changeSortingKey();
      this.fetchAllProductsByCollection(value , name);
    });
  }

  renderFilterUI = () => {
    const { filterType, category, menuBarItems } = this.state;
    return (<div className="filters-toolbar-wrapper">
      <div className="page-width">
        <div className="filter-toolbar-layout">
          <div className="space-drop-down">
            <label>SORT BY</label>
            <select name="filterType" value={filterType} onChange={this.onValueChange}>
              <option value="ID">Featured</option>
              <option value="RELEVANCE">Relevance</option>
              <option value="TITLE" selected="selected">Title</option>
              <option value="PRICE">Price (low to high)</option>
              <option value="CREATED_AT">Date, old to new</option>
              <option value="UPDATED_AT">Date, new to old</option>
            </select>
          </div>
          <div className="total-product space-drop-down">
              <label>Category</label>
              <select name="category" value={category} onChange={this.onValueChange}>
                {menuBarItems.map((each, index) => <option key={index + 1} value={each.id}>{each.title}</option>)}
              </select>
          </div>
        </div>
      </div>
    </div>)
  }

  render() {
    const { products, title, loader , hasNextPage , hasPreviousPage } = this.state;
    return (
      <div>
        {renderMetaTag({
          title: "Product Listing | Festival Season",
          description: "Product Listing | Festival Season",
          keywords: "Festival Season , Festival Sales"
        })}
        {!loader ? <div className="page-width">
          <h1 className="capitalize margin-top-align">{title ? title.replace(/-/g , " ") : "Products"}</h1>
        </div> : null}
        {this.renderFilterUI()}
        {!loader ? <div className="page-width">
          <Products
            products={products}
            client={client}
            addVariantToCart={this.addVariantToCart}
          />
          {products && products.length ? <div className="pagination">
            <button disabled={!hasPreviousPage} onClick={() => { this.previousPage() }} >
              <svg aria-hidden="true" focusable="false" role="presentation"
                className="icon icon--wide icon-arrow-left" viewBox="0 0 20 8">
                <path d="M4.814 7.555C3.95 6.61 3.2 5.893 2.568 5.4 1.937 4.91 1.341 4.544.781 4.303v-.44a9.933 9.933 0 0 0 1.875-1.196c.606-.485 1.328-1.196 2.168-2.134h.752c-.612 1.309-1.253 2.315-1.924 3.018H19.23v.986H3.652c.495.632.84 1.1 1.036 1.406.195.306.485.843.869 1.612h-.743z" fill="#000" fillRule="evenodd">
                </path></svg>
            </button>
            <button disabled={!hasNextPage} onClick={() => { this.nextPageProduct() }} >
              <svg aria-hidden="true" focusable="false" role="presentation" className="icon icon--wide icon-arrow-right" viewBox="0 0 20 8"><path d="M15.186.445c.865.944 1.614 1.662 2.246 2.154.631.491 1.227.857 1.787 1.098v.44a9.933 9.933 0 0 0-1.875 1.196c-.606.485-1.328 1.196-2.168 2.134h-.752c.612-1.309 1.253-2.315 1.924-3.018H.77v-.986h15.577c-.495-.632-.84-1.1-1.035-1.406-.196-.306-.486-.843-.87-1.612h.743z" fill="#000" fillRule="evenodd"></path>
              </svg>
            </button>
          </div> : <div className="category-not-found-ui">
              <div className="category-not-found"> 
              </div>
            </div>}
        </div> : <Loader />}
      </div>
    );
  }
}


export default compose(withRouter, connect())(ProductListing);


