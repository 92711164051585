import React, { Component } from 'react';

import { client } from "../index";

import Products from '../components/Products';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { compose } from 'redux';

import { STORE_CHECKOUT_CLIENT , GET_CHECKOUT_ID } from '../utils/redux-utils';

import Loader from "../utils/Loader";

const { renderMetaTag } = require('../utils/seoTags');

const { fetchSideMenuBar } = require('../utils/index');

const noImage = "https://cdn.shopify.com/shopifycloud/shopify/assets/no-image-2048-5e88c1b20e087fb7bbe9a3771824e743c244f437e4f8ba93bbf7b11b53f7824c_grande.gif"

const feedbackData = [
    { author: "Alyssa Burch", feedback: "Festival Season one of the best E-commerce Store & 24/7 Customer Care Support." },
    { author: "Mark Paris", feedback: "Hey Team Festival Season, I have gone through your website and it is Impressive, Thank You For Your Service" },
    { author: "Micheal Vargas", feedback: "Faster Delivery, Trustworthy Products to Make Customers Feel Happy and Safe" },
]

class Home extends Component {

    state = {
        products: [],
        menuBarItems: []
    }

    renderTestCustomerFeedBack = (payload , index) => {
        const { author, feedback } = payload;
        return <div className="textCenter" key={index}>
            <span className="quote-icon"><svg aria-hidden="true" focusable="false" role="presentation" className="icon icon-quote" viewBox="0 0 41 35"><path d="M10.208 17.711h6.124v16.332H0V21.684C0 8.184 5.444.956 16.332 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766zm24.498 0h6.124v16.332H24.498V21.684C24.498 8.184 29.942.956 40.83 0v6.125c-4.083 1.14-6.124 4.414-6.124 9.82v1.766z" fill="#000" fillRule="evenodd"></path></svg></span>
            <div> <p className="feedbackText">{feedback}</p> </div>
            <br></br>
            <div className="author"> {"---  "} {author}</div>
            <br></br>
        </div>
    }

    renderFeedback = () => {
        return <div className="page-width index-section">
            <div className="flex feedback">
                {feedbackData.map((feedback, index) => this.renderTestCustomerFeedBack(feedback, index))}
            </div>
        </div>
    }

    renderCollectionName = (payload) => {
        const { image, title, id, value } = payload;
        return <div key={id} className="Product cursor" onClick={() => {
            this.props.history.push(`/collection/${value}`);
        }}>
            <div>
                <img alt={id} src={image ? image : noImage} />
            </div>
            <div className="collection-name">{title}</div>
        </div>
    }
    render() {
        const { products , menuBarItems } = this.state;
        return (
            <div>
                {renderMetaTag({
                    title: "Festival Season",
                    description: "“Life is Good”, “Do What You Love. Love What You Do.”, “Good Vibes”, “Half Full”, “Powered By Optimism”, “Positive Lifestyle”, the Jake logo “Art of Optimism”, “Fueled by Positive Energy”, “Spread Good Vibes”, “Today is a Good Day”, “Take It Easy” and “Keep It Simple” ",
                    keywords: "Festival Season , Festival Sales"
                })}
                <header className="App__header">
                    <div className="App__title">
                        <h1 className="font-35">Festival Season</h1>
                        <h2 className="font-50">Designed To Elevate Your LifeStyle</h2>
                        <br></br>
                        <h2>“Happiness is not in money, but in shopping.”</h2>
                    </div>
                </header>

                <div className="page-width margin-top-align">
                    <div>
                        <h2 className="featured-items">Featured collection</h2>
                    </div>

                    <div className="flex flex-wrap">
                        {menuBarItems.map((eachCollection) => this.renderCollectionName(eachCollection))}
                    </div>
                </div>

                {products && products.length ? <div className="page-width margin-top-align">
                    <div>
                        <h2 className="featured-items">Products</h2>
                    </div>
                    <Products
                        products={products}
                        client={client}
                        addVariantToCart={this.addVariantToCart}
                    />
                    <div className="view-all">
                        <a className="continue-shop" href="/collection">VIEW ALL</a>
                    </div>
                </div> : <Loader />}

                
                {this.renderFeedback()}

                <header className="App_Footer">
                    <div className="App__title">
                        <h1 className="font-35">Festival Season</h1>
                    </div>
                </header>
            </div>
        );
    }

    addVariantToCart = async (variantId, quantity) => {

        const lineItemsToAdd = [{ variantId, quantity: parseInt(quantity, 10) }]

        const checkoutId = GET_CHECKOUT_ID();

        const response = await client.checkout.addLineItems(checkoutId, lineItemsToAdd);

        STORE_CHECKOUT_CLIENT(this.props, { checkout: response });

        this.props.history.push("/cart");
    }


    componentDidMount = async () => {

        const { product } = client || {};

        const productResponse = await product.fetchQuery({ first: 8 });

        this.updateState({ products: productResponse, menuBarItems: await fetchSideMenuBar()});
    }

    updateState = (state) => this.setState(state);

}

export default compose(withRouter, connect())(Home);