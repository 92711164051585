import React, { Component } from 'react';

import { commonInstructionUI } from '../components/commonUI';

class NotFound extends Component {

    state = {
        title: '404 Page Not Found',
        description: 'The page you requested does not exist.'
    }
    render() {
        const { title, description } = this.state
        return (
            <div>{commonInstructionUI({ title, description })}</div>
        );
    }
}

export default NotFound;