import actionTypes from '../type/index';

const {
    SET_CART_PRODUCTS,
    UPDATE_CART_PRODUCTS,
    TRUNCATE_CART_INFO
} = actionTypes;


export const setCartInfo = payload => {
    return {
        type: SET_CART_PRODUCTS,
        payload
    }
}

export const updateCartInfo = payload => {
    return {
        type: UPDATE_CART_PRODUCTS,
        payload
    }
}

export const truncateCartInfo = payload => {
    return {
        type: TRUNCATE_CART_INFO,
        payload
    }
}