import React, { Component } from 'react';

class Contact extends Component {
    render() {
        return (
            <div className="page-width contact-us">

                <h1>CONTACT US</h1>

                <br></br> <br></br>

                <h3> Our Support Staff is working 7 days a week to handle any requests, please contact us by using the form or reach us at <br></br> <br></br> support@onlinedevtools.in</h3>
            </div>
        );  
    }
}

export default Contact;