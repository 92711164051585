import { store } from '../store/store';

import { setCartInfo } from '../store/action/cart';

export const GET_CHECKOUT_CLIENT = () => {

    let { cartReducer = {} } = store.getState();

    let { productInfo = {} } = cartReducer;

    let { checkout = "" } = productInfo;

    return checkout;
}

export const GET_CHECKOUT_COUNT = () => {

    let { cartReducer = {} } = store.getState();

    let { productInfo = {} } = cartReducer;

    let { checkout = {} } = productInfo;

    let { lineItems = [] } = checkout;

    return lineItems.length;
}

export const GET_CHECKOUT_ID = () => {

    let { cartReducer = {} } = store.getState();

    let { productInfo = {} } = cartReducer;

    let { checkout = {} } = productInfo;

    return checkout && checkout.id;
}

export const showCartPage = () => {

    let { isCartOpen = false } = store.getState();

    return isCartOpen;
}

export const STORE_CHECKOUT_CLIENT = (props, payload) => {

    return props.dispatch(setCartInfo(payload));
    
}