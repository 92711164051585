import React, { Component } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ProductListing from '../components/ProductListing';
import ProductDetails from '../components/ProductDetails';
import Home from '../components/Home';
import Instruction from '../components/Instruction';
import CartUI from '../components/CartUI';
import Contact from '../components/Contact';
import NotFound from '../components/NotFound';
export class Navigator extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/collection" component={ProductListing} />
          <Route path="/product" component={ProductDetails} />
          <Route path="/not-found" component={NotFound} />
          <Route path="/shopping-instruction" component={Instruction} />
          <Route path="/cart" component={CartUI} />
          <Route path="/contact-us" component={Contact} />
          <Route path="/" component={Home} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Navigator
