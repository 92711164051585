import React, { Component } from 'react';

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { compose } from 'redux';

class LineItem extends Component {
  constructor(props) {

    super(props);

    this.decrementQuantity = this.decrementQuantity.bind(this);

    this.incrementQuantity = this.incrementQuantity.bind(this);
  }

  decrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity - 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  incrementQuantity(lineItemId) {
    const updatedQuantity = this.props.line_item.quantity + 1
    this.props.updateQuantityInCart(lineItemId, updatedQuantity);
  }

  render() {
    return (
      <li className="Line-item">

        <div className="flex center-item-align">

          <div className="flex flex5 center-item-align flex-col-mobile flex-start">

            <div> {this.props.line_item.variant.image ?
              <img className="cart__image" src={this.props.line_item.variant.image.src} alt={`${this.props.line_item.title} product shot`} /> : null}
            </div>

            <div className="cart_product_padding">
              <div className="cart_product_title" onClick={() => {
                this.props.history.push(`/product/${(this.props.line_item.title.toLowerCase().replace(/'/g , "")).replace(/\s/g, "-")}`);
              }}>
                {this.props.line_item.title}
              </div>  <br></br>
              <div className="cart_variant_title">{this.props.line_item.variant.title}</div> <br></br>
              <div>
                <span onClick={() => this.props.removeLineItemInCart(this.props.line_item.id)} className="text-link text-link--accent">  Remove </span>
              </div>
            </div>

          </div>

          <div className="flex-one center-item-align display-none">
            <div className="Line-item__price"> $ {this.props.line_item.variant.price}</div>
          </div>

          <div className="flex-one center-item-align flex-two-mobile">
            <div className="Line-item__quantity-container">
              <button className="Line-item__quantity-update" onClick={() => this.decrementQuantity(this.props.line_item.id)}>-</button>
              <span className="Line-item__quantity">{this.props.line_item.quantity}</span>
              <button className="Line-item__quantity-update" onClick={() => this.incrementQuantity(this.props.line_item.id)}>+</button>
            </div>
          </div>

          <div className="flex05 center-item-align flex-one-mobile">
            <div className="Line-item__price"> $ {(this.props.line_item.quantity * this.props.line_item.variant.price).toFixed(2)}</div>
          </div>

        </div>
      </li>
    );
  }
}

export default compose(withRouter, connect())(LineItem);
