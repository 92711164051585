import React, {Component} from 'react';

class VariantSelector extends Component {
  render() {

    let { name , values } = this.props.option || {};

    let { handleOptionChange } = this.props;

    return (
      <div className="selector">
        <label>{name}</label>
        <select
          className="Product__option"
          name={name}
          key={name}
          onChange={handleOptionChange}
        >
          {values.map((each) => {
            return (
              <option value={each.value} key={`${name}-${each.value}`}>{`${each.value}`}</option>
            )
          })}
        </select>
      </div>
    );
  }
}

export default VariantSelector;
