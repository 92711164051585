import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducer from './reducers/index'
import { composeWithDevTools } from 'redux-devtools-extension';
import { encryptTransform } from 'redux-persist-transform-encrypt';
const { config: { ENCRYPTION_TOKEN } } = require('../config')

const encryptionConfig = encryptTransform({
  secretKey: ENCRYPTION_TOKEN,
  onError: (error) => {
    console.log(`Security issue breached while encrypting!`, error);
  }
})

const persistConfig = {
  key: 'root',
  storage,
  transforms: [encryptionConfig]
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware()))

const persistor = persistStore(store);

const configureStore = () => ({ store, persistor });

export default configureStore;
