const PROD_DEPLOYMENT_URL = "";

const DEV_DEPLOYMENT_URL = "";

// storefrontAccessToken: '346956b5fc3b4e696d06399892e99a6f',
// domain: 'petshops005.myshopify.com'

const PROD_CONFIG = {
    STORE_ACCESS_TOKEN: 'dd4d4dc146542ba7763305d71d1b3d38',
    PRODUCTS_DATA_PULL_DOMAIN: 'graphql.myshopify.com',
    ENCRYPTION_TOKEN :  'RYCCX7TR!S`,xdZV' 
}

const DEV_CONFIG = {
    STORE_ACCESS_TOKEN: 'dd4d4dc146542ba7763305d71d1b3d38',
    PRODUCTS_DATA_PULL_DOMAIN: 'graphql.myshopify.com',
    ENCRYPTION_TOKEN: 'RYCCX7TR!S`,xdZV'
}

/**
 * @description Function that returns config information based on deployment URL
 * @name getConfig
 * @returns {Object}
 */
const getConfig = () => {

    let config;

    switch (window.location.hostname) {

        case PROD_DEPLOYMENT_URL:
            config = PROD_CONFIG;
            break;

        case DEV_DEPLOYMENT_URL:
            config = DEV_CONFIG;
            break;

        default:
            config = DEV_CONFIG;
    }

    return config

}

module.exports = { config: getConfig() }