import actionType from '../type/index';

const {
    SET_CART_PRODUCTS,
    UPDATE_CART_PRODUCTS,
    TRUNCATE_CART_INFO
} = actionType;

const initialState = {
    productInfo: []
}

function cartReducer(state = initialState, action) {

    let { productInfo = [] } = state;

    switch (action.type) {

        case SET_CART_PRODUCTS: {
            const newProductInfo = action.payload;
            return Object.assign({}, state, {
                productInfo: newProductInfo
            })
        }

        case UPDATE_CART_PRODUCTS: {
            const newProducts = action.payload;
            return {
                ...state,
                productInfo: { ...productInfo, ...newProducts }
            }
        }

        case TRUNCATE_CART_INFO: {
            return initialState;
        }

        default:
            return state
    }
}

export default cartReducer;