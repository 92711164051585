
import React from 'react';

const { renderMetaTag } = require('../utils/seoTags');

export const commonInstructionUI = (payload) => {
    let { title, description } = payload;
    return <div className="page-width">
        {renderMetaTag({
            title,
            description,
        })}
        <div className="empty-page-content text-center">
            <h1>{title}</h1>
            <p className="body-text">{description}</p>
            <p>
                <a className="continue-shop" href="/collection">CONTINUE SHOPPING</a>
            </p>
        </div>
    </div>
}