import { client } from "../index";

/**
 * @param {string} keyName - A key to identify the value.
 * @param {any} keyValue - A value associated with the key.
 * @param {number} ttl- Time to live in seconds.
 */
export const setItemIntoLocalStorage = (keyName, keyValue, ttl) => {

    const data = {
        value: keyValue,                  // store the value within this object
        ttl: Date.now() + (ttl * 1000),   // store the TTL (time to live)
    }

    // store data in LocalStorage 
    localStorage.setItem(keyName, JSON.stringify(data));
};


/**
 * @param {string} keyName - A key to identify the data.
 * @returns {any|null} returns the value associated with the key if its exists and is not expired. Returns `null` otherwise
 */
 export const getItemFromLocalStorage = (keyName) => {

    const data = localStorage.getItem(keyName);

    if (!data) return null;

    const item = JSON.parse(data);

    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) return localStorage.removeItem(keyName);

    // return data if not expired
    return item.value;
};

export const fetchSideMenuBar = async () => {

    const { collection } = client || {};

    const collectionResponse = await collection.fetchAllWithProducts();

    const menuBarItems = [];

    collectionResponse.map((each) => menuBarItems.push({ image: (each.image && each.image.src), title: each.title, id: each.id, value: each.handle }));

    setItemIntoLocalStorage('menuBarItems', menuBarItems, 300);

    return menuBarItems;
}