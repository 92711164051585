import React, { Component } from 'react';

const { renderMetaTag } = require('../utils/seoTags');
class Instruction extends Component {
    render() {
        return (
            <div className="shopping-padding">

                {renderMetaTag({
                    title: "Shipping & Delivery",
                    description: "Shipping & Delivery | Festival Season",
                    keywords: "Shipping , Delivery , Festival Season"
                })}

                <h1>Shipping & Delivery</h1>

                <br></br>

                <div>
                    <h2 className="textCenter"> <strong> Our goal is to offer you the best shipping options, <br></br>
                no matter where you live!</strong></h2>
                    <br></br>
                    <img alt="Shipping & Delivery" src="https://cdn.shopify.com/s/files/1/0590/7873/8097/files/signing-shipping-forms-for-large-box_720x.jpg?v=1628863960" />
                </div>
                <br></br>
                <br></br>

                <p>By purchasing from Festival Season, you agree with this Policy.</p>

                <p> <strong>Item Shipping.</strong> All orders are prepared and shipped within <strong>2-7 business days.</strong> This depends on the inventory <br></br>
                level, because some items are made on demand. Once the order is shipped, the email notification with a <br></br>
                tracking code is sent to the customer.</p>

                <p>Delivery Time. The delivery time depends on each product and the customer country. You can find the <br></br>
                delivery time on each product page in the description section. Please expect the bracelet delivery time to <br></br>
                be <strong> 7-17 days </strong> to the USA and Canada, and <strong>5-10 days</strong> to Europe.</p>

                <p>
                    Delivery time is stated in calendar days. The Delivery time may be longer than stated during the high season <br></br>
                    and (national) holidays. Festival Season does not take the responsibility for the delivery delays that happen due third- <br></br>
                    party services.
                </p>

                <p>
                    Festival Season will not be held responsible for lost goods if an incorrect shipping address is provided.
                </p>

                <p>
                    Could your package not show up, or arrive damaged, please contact us at: admin@onlinedevtools.in.
                </p>
            </div>
        );
    }
}

export default Instruction;