import React, { Component } from 'react';

import { commonInstructionUI } from '../components/commonUI';

import { GET_CHECKOUT_CLIENT, STORE_CHECKOUT_CLIENT , GET_CHECKOUT_ID } from '../utils/redux-utils';

import LineItem from './LineItem';

import { client } from "../index";

import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { compose } from 'redux';

import Loader from "../utils/Loader";

import { Fragment } from 'react';

class CartUI extends Component {

    constructor(props) {
        super(props);
        this.openCheckout = this.openCheckout.bind(this);
        this.updateQuantityInCart = this.updateQuantityInCart.bind(this);
        this.removeLineItemInCart = this.removeLineItemInCart.bind(this);
        this.state = {
            title: 'Your cart',
            description: 'Your cart is currently empty.',
            lineItems: [],
            webUrl: "",
            loader: false
        }
    }

    openCheckout = (WEB_URL) => window.open(WEB_URL);

    updateQuantityInCart = async (lineItemId, quantity) => {

        this.updateState({ loader: true });

        const checkoutId = GET_CHECKOUT_ID();
    
        const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }]
    
        const response = await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
    
        STORE_CHECKOUT_CLIENT(this.props, { checkout: response });
    
        this.updateState({ checkout: GET_CHECKOUT_CLIENT() , loader: false });
      }

      updateState = (state) => this.setState(state);
    
      removeLineItemInCart = async (lineItemId) => {

        this.updateState({ loader: true });
    
        const checkoutId = GET_CHECKOUT_ID()
    
        const response = await client.checkout.removeLineItems(checkoutId, [lineItemId]);
    
        STORE_CHECKOUT_CLIENT(this.props, { checkout: response });
    
        this.updateState({ checkout: GET_CHECKOUT_CLIENT() , loader: false });
      }

    render() {
        const { title, description, checkout, loader } = this.state;

        let { lineItems = [], subtotalPrice, totalTax, totalPrice, webUrl } = checkout || {};

        let line_items = lineItems.map((line_item) => {
            return (
                <LineItem
                    updateQuantityInCart={this.updateQuantityInCart}
                    removeLineItemInCart={this.removeLineItemInCart}
                    key={line_item.id.toString()}
                    line_item={line_item}
                />
            );
        });

        return (
            <div className="margin-top-align">
                {lineItems && lineItems.length === 0 ? <div>{commonInstructionUI({ title, description })}</div> :
                    <div className="page-width">

                        {loader ? <Loader /> : <Fragment>
                            <div className="textCenter">
                                <h1 className="cart-header__title">Your Cart</h1>
                                <a href="/collection" className="text-link text-link--accent">  Continue Shopping </a>
                            </div>
                            <div className="cart-page-header">
                                <div className="flex5"> PRODUCT </div>
                                <div className="flex-one textCenter display-none"> PRICE </div>
                                <div className="flex-one textCenter flex-two-mobile"> QUANTITY </div>
                                <div className="flex05 textCenter"> TOTAL </div>
                            </div>

                            <div>
                                <ul className="Cart__line-items">
                                    {line_items}
                                </ul>
                            </div>

                            <footer className="Cart__footer">
                                <div className="Cart-info">
                                    <div className="Cart-info__total Cart-info__small">Subtotal</div>
                                    <div className="Cart-info__pricing">
                                        <span className="pricing">$ {subtotalPrice}</span>
                                    </div>
                                </div>
                                <div className="Cart-info">
                                    <div className="Cart-info__total Cart-info__small">Taxes</div>
                                    <div className="Cart-info__pricing">
                                        <span className="pricing">$ {totalTax}</span>
                                    </div>
                                </div>
                                <div className="Cart-info">
                                    <div className="Cart-info__total Cart-info__small">Total</div>
                                    <div className="Cart-info__pricing">
                                        <span className="pricing">$ {totalPrice}</span>
                                    </div>
                                </div>
                                <div className="Cart__footer-info">Taxes and shipping calculated at checkout</div>
                                <div className="Cart__checkout-end">
                                    <button className="Cart__checkout button" onClick={() => { this.openCheckout(webUrl) }}>CHECK OUT</button>
                                </div>
                            </footer>

                        </Fragment>}

                    </div>}
            </div>
        );
    }

    componentDidMount() {

        let checkout = GET_CHECKOUT_CLIENT();

        this.setState({ checkout });
    }
}

export default compose(withRouter, connect())(CartUI);
