import React, {Component} from 'react';
// import VariantSelector from './VariantSelector';

class Product extends Component {
  constructor(props) {
    super(props);

    let defaultOptionValues = {};
    this.props.product.options.forEach((selector) => {
      defaultOptionValues[selector.name] = selector.values[0].value;
    });
    this.state = { selectedOptions: defaultOptionValues };

    // this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleQuantityChange = this.handleQuantityChange.bind(this);
    this.findImage = this.findImage.bind(this);
  }

  findImage(images, variantId) {
    const primary = images[0];

    const image = images.filter(function (image) {
      return image.variant_ids.includes(variantId);
    })[0];

    return (image || primary).src;
  }

  handleOptionChange(event) {
    const target = event.target
    let selectedOptions = this.state.selectedOptions;
    selectedOptions[target.name] = target.value;

    const selectedVariant = this.props.client.product.helpers.variantForOptions(this.props.product, selectedOptions)

    this.setState({
      selectedVariant: selectedVariant,
      selectedVariantImage: selectedVariant.attrs.image
    });
  }

  handleQuantityChange(event) {
    this.setState({
      selectedVariantQuantity: event.target.value
    });
  }

  render() {
    let variantImage = this.state.selectedVariantImage || this.props.product.images[0]
    let variant = this.state.selectedVariant || this.props.product.variants[0]
    let variantQuantity = this.state.selectedVariantQuantity || 1
    // let variantSelectors = this.props.product.options.map((option) => {
    //   return (
    //     <VariantSelector
    //       handleOptionChange={this.handleOptionChange}
    //       key={option.id.toString()}
    //       option={option}
    //     />
    //   );
    // });
    const { title, handle , availableForSale } = this.props.product;
    return (
      <div className="Product">
        {this.props.product.images.length ? <img src={variantImage.src} alt={`${title} product shot`}/> : null}
        <h5 className="Product__title" onClick={() => {  window.location.href = `${window.location.origin}/product/${handle}` }} >{title}</h5>

        {/* <div className="description" dangerouslySetInnerHTML={{__html: this.props.product.descriptionHtml}}></div> */}
        <div className="Product__price">${variant.price}</div>
        {/* {variantSelectors} */}
        {/* <div className="quantity">
          <label>Quantity</label>
          <input min="1" type="number" defaultValue={variantQuantity} onChange={this.handleQuantityChange}></input>
        </div> */}
    
        <div className="buy-button">
          {availableForSale ? <button className="Product__buy button primary-color" onClick={() => this.props.addVariantToCart(variant.id, variantQuantity)}>ADD TO CART</button>: 
          <button className="Product__buy button cursor-not-allow primary-color"> SOLD OUT </button>}
        </div>
      </div>
    );
  }
}

export default Product;
